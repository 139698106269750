<template>
    <div 
        class="select-slider-module-container"
        @dblclick="setSelect()"
        v-if="config.panel.length != 0"
     >    
        <i  class="fas fa-chevron-left"  
            @click="index--" 
        />
        <div class="select-slider-image-container">
            <div 
                class="select-slider-image" 
                :style="'background-image: url('+ getImg(config.panel[index].image) +')'"
            />
        </div>
        <div class="select-slider-text-container">
            <div 
                class="select-slider-titles" 
                v-if="config.panel.length != 0"
            >
                <inline-edit  
                    :target="config.panel[index].title" 
                    element="h1"
                />
                <inline-edit 
                    :target="config.panel[index].subtitle" 
                    element="h2"
                />
                <inline-edit 
                    :target="config.panel[index].texttitle" 
                    element="h3"
                />
            </div>
            <div class="select-slider-text">
                <inline-edit 
                    :target="config.panel[index].text" 
                    element="p"
                />
            </div>
            <div v-if="haveMarks" class="image-dots-container">
                <i 
                    v-for="j in config.panel.length" 
                    :key="'image-dots-' + j" 
                    class="fas fa-circle" 
                    :style="isFaded(j-1)"
                    @click="index = (j-1)"
                />
            </div>
        </div>
        <i  class="fas fa-chevron-right"  
            @click="index++" 
        />
    </div>
    <div v-else>double click ...</div>
</template>

<script>

    import * as imgManager from "@/store/imgManager";

    export default {
        props: ["config"],
        data(){
            return {
                index: 0,
                haveMarks: true
            }
        },
        watch: {
            index(){
                if(this.index >= this.config.panel.length){
                    this.index = 0;
                }
                else if(this.index < 0){
                    this.index = this.config.panel.length - 1;
                }
            }
        },
        methods:{
            setSelect(){
                this.$store.state.selectedSlider = this.index;
            },
            isFaded(i){
                if(i == this.index)
                {
                    return "opacity: 100%;";
                }
                return "opacity: 0.2; -moz-opacity: 0.2; -webkit-opacity: 0.2; filter: alpha(opacity=50);"
            },
            getImg(image){
                        let img = imgManager.getImage(image);
                        if(img){
                              if(img.path){
                                    return img.path;
                              }else{
                                    return img.file;
                              }
                        }
                  }
        },
        mounted(){
            if(!this.config.panel){
                    this.config.panel = []
            }
            for(let i=0; i < this.config.panel.length; i++){
                if(!this.config.panel[i].image){
                    this.config.panel[i]["image"] = ""
                }


                if(!this.config.panel[i].title){
                    this.config.panel[i]["title"] = ""
                }
                if(typeof this.config.panel[i].title === 'string'){
                    this.config.panel[i].title = {value: this.config.panel[i].title}
                }


                if(!this.config.panel[i].subtitle){
                    this.config.panel[i]["subtitle"] = ""
                }
                if(typeof this.config.panel[i].subtitle === 'string'){
                    this.config.panel[i].subtitle = {value: this.config.panel[i].subtitle}
                }


                if(!this.config.panel[i].text){
                    this.config.panel[i]["text"] = ""
                }
                if(typeof this.config.panel[i].text === 'string'){
                    this.config.panel[i].text = {value: this.config.panel[i].text}
                }


                if(!this.config.panel[i].texttitle){
                    this.config.panel[i]["texttitle"] = ""
                }
                if(typeof this.config.panel[i].texttitle === 'string'){
                    this.config.panel[i].texttitle = {value: this.config.panel[i].texttitle}
                }
            }
        }
    }
</script>